import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Globals } from '../globals';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AlmacenesService {
  public caracteristicas = [
    {txt: 'Oficinas Administrativas', valor: 'oficinas_admin',isChecked: false},
    {txt: 'Alumbrado público tipo led', valor: 'alumbrado_led',isChecked: false},
    {txt: 'Acceso controlado 24/7', valor: 'acceso24/7',isChecked: false},
    {txt: 'Subestación eléctrica', valor: 'subestacion_electrica',isChecked: false},
    {txt: 'Telecomunicaciones (voz y datos)', valor: 'telecomunicaciones',isChecked: false},
    {txt: 'Drenaje pluvial', valor: 'drenaje_pluvial',isChecked: false},
    {txt: 'Drenaje sanitario', valor: 'deranje_sanitario',isChecked: false},
    {txt: 'Sección aduanera interior', valor: 'seccion_aduanera',isChecked: false},
    {txt: 'Vialidades de concreto hidráulico', valor: 'vialidades_concreto',isChecked: false},
    {txt: 'Energía eléctrica línea de media tensión', valor: 'energia_media_tension',isChecked: false},
    {txt: 'Centro de negocios', valor: 'centro_negocios',isChecked: false},
    {txt: 'Sistema contra incendio', valor: 'sistema_incendios',isChecked: false},
    {txt: 'Terracería tratada', valor: 'terraceria_tratada',isChecked: false},
    {txt: 'Área Verde', valor: 'area_verde',isChecked: false},
    {txt: 'Área de Espera', valor: 'area_espera',isChecked: false},
    {txt: 'Bascula para camiones', valor: 'bascula',isChecked: false},
    {txt: 'Volcador para camiones', valor: 'volcador',isChecked: false},
  ]
  constructor(private http: HttpClient,private globals:Globals) { }
  getAll(tipo) {
    return this.http.get(`${this.globals.apiUrl}/almacenes/getAll?tipo=${tipo}`).pipe(catchError(this.handleError));
  }

  agregar(almacen) {
    return this.http.post(`${this.globals.apiUrl}/almacenes/agregar`, almacen).pipe(catchError(this.handleError));
  }

  editar(almacen) {
    return this.http.post(`${this.globals.apiUrl}/almacenes/editar`, almacen).pipe(catchError(this.handleError));
  }

  eliminar(id,tipo) {
    return this.http.post(`${this.globals.apiUrl}/almacenes/eliminar`, {
      id: id,
      tipo
    }).pipe(catchError(this.handleError));
  }
  resetCaracteristicas() {
    this.caracteristicas.forEach(item => {
      item.isChecked = false;
    });
  }
  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };
}
